import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { RelatedArticles } from '../components/RelatedArticles'
import { CategoryLanding } from '../components/CategoryLanding'
import SEO from '../components/seo'

// import { ResponsiveImage } from '../components/ResponsiveImage';

class PageTemplate extends Component {
  render() {
    const post = this.props.data.page
    const subcategories = this.props.data.allWpPage.nodes

    return (
      <Layout headerTitle={post.title} generic>
        <SEO
          title={post.seo.title}
          image={post?.avContent?.featureImage?.sourceUrl}
          description={post.seo.metaDesc}
        />

        <CategoryLanding
          introHeading={post.avIntro.introHeading}
          introDesc={post.avIntro.introDescription}
          subcategories={subcategories}
        />

        <RelatedArticles
          noBorder
          heading={post.avRelatedPages.relatedHeading}
          relatedPages={post.avRelatedPages.relatedPages}
        />
      </Layout>
    )
  }
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...RelatedPages
      seo {
        title
        metaDesc
      }
      content
      categories {
        nodes {
          name
          slug
        }
      }
      avIntro {
        introDescription
        introHeading
      }
      avContent {
        featureImage {
          sourceUrl
        }
      }
    }
    allWpPage(
      filter: { wpParent: { node: { id: { eq: $id } } } }
      sort: { fields: menuOrder, order: ASC }
    ) {
      nodes {
        title
        slug
        title
        excerpt
        date
        menuOrder
        uri
        templateSlug
        parentDatabaseId
        avIntro {
          introHeading
          introDescription
        }
        avContent {
          featureImage {
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`
